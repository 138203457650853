var ALLJOBS = 'ALLJOBS';
var allButtons = document.querySelectorAll('.department-button');
var allOpenings = document.querySelectorAll('.job-opening');

function showDepartment(department) {
  allOpenings.forEach(function (opening) {
    if (department === ALLJOBS || opening.classList.contains('job-department-' + department)) {
      opening.classList.remove('hide');
    } else {
      opening.classList.add('hide');
    }
  });

  allButtons.forEach(function (button) {
    if (button.id === department) {
      button.classList.add('active');
    } else {
      button.classList.remove('active');
    }
  });
}

allButtons.forEach(function (button) {
  button.addEventListener('click', function (e) {
    showDepartment(button.id);
    button.classList.add('active');
    e.preventDefault();
  });
});
