window.hover1 = function (mem) {
  hover(mem, 1);
}

window.hover2 = function(mem) {
  hover(mem, 2);
}

window.hover3 = function (mem) {
  hover(mem, 3);
}

window.hover4 = function (mem) {
  hover(mem, 4);
}

window.hover5 = function (mem) {
  hover(mem, 5);
}

window.hover = function(mem, number) {
    document.getElementById(`${mem}info`).removeAttribute('hidden');
    document.getElementById(`${mem}info`).innerHTML = '<i>Click to rate this meme ' + number + ' stars!</i>';
    for(let i = 1; i < 6; i++) {
        const element = document.getElementById(`${mem}${i}`);
        const current = element.getAttribute('class');
        if(i === number) {
            element.setAttribute('class', current + ' star-selected');
        } else {
            element.setAttribute('class', current.replace(" star-selected", ""));
        }
    }
}

window.change = function(mem, number) {
    document.getElementById(`${mem}info`).removeAttribute('hidden');
    document.getElementById(`${mem}info`).innerHTML = '<i>Click to rate this meme ' + number + ' stars!</i>';
    for(let i = 1; i < 6; i++) {
        document.getElementById(`${mem}${i}`).setAttribute('class', 'fa fa-star');
    }
    for(let i = 1; i <= number; i++) {
        document.getElementById(`${mem}${i}`).setAttribute('class', 'fa fa-star checked');
    }
}
